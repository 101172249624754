import React, {Component} from "react"
import ReactMarkdown from "react-markdown"
import rehypeRaw from 'rehype-raw'
import Layout from "../components/layout"
import Header from "../components/header"
import {graphql, navigate} from 'gatsby'
import Helmet from "react-helmet";
import 'babel-polyfill'

export default class referenzenDetailsPage extends React.Component {

    constructor() {
        super();
        this.state = {};
    }

    state = {
        technologien: false,
        author: false,
        slide: false
    };

    toggle = property => {
        this.setState({[property]: !this.state[property]})
    };

    componentDidMount() {
        if (typeof (window) !== "undefined") {
            let header = Array.from(document.getElementsByTagName("h2"));
            header.forEach(headers => {
                    let nextUntil = function (elem, selector) {
                        let siblings = [];
                        elem = elem.nextElementSibling;
                        while (elem) {
                            if (elem.matches(selector)) break;
                            siblings.push(elem);
                            elem = elem.nextElementSibling;
                        }
                        return siblings;
                    };
                    let parelement = nextUntil(headers, "h2");
                    for (let j = 0; j < parelement.length; j++) {
                        parelement[j].style.display = "none";
                    }
                    headers.onclick = () => {
                        let par = nextUntil(headers, "h2");
                        for (let j = 0; j < par.length; j++) {
                            if (par[j].style.display === "none") {
                                par[j].style.display = "block";
                                headers.classList.remove("h2-disactive");
                                headers.classList.add("h2-active");
                            } else {
                                par[j].style.display = "none";
                                headers.classList.remove("h2-active");
                                headers.classList.add("h2-disactive");
                            }
                        }
                    }
                }
            );
            let link = Array.from(document.getElementById("content").getElementsByTagName("a"));
            link.forEach(links => links.target = "_blank");
            let image = Array.from(document.getElementsByTagName('img'));
            image.forEach(images => images.title = images.alt);
            if (this.props.data.strapiReferences.testimonial !== null && this.props.data.strapiReferences.testimonial.length > 0) {
                let testimonialSmall = document.getElementsByClassName('testimonial-small');
                let testimonialBig = document.getElementsByClassName('testimonial-big');
                if (window.innerWidth > 767) {
                    testimonialSmall[0].style.display = "none";
                    testimonialBig[0].style.display = "block";
                } else {
                    testimonialSmall[0].style.display = "block";
                    testimonialBig[0].style.display = "none";
                }
            }
        }

    }

    render() {
        const referenzen = this.props.data.strapiReferences;
        let {
            technologien,
            author,
            slide
        } = this.state;

        let movie = referenzen.filmes;
        const offersData = this.props.data.allStrapiOfferPages.edges;
        return (
            <Layout page="reference">
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{referenzen.meta_title} | Paixon</title>
                    <meta name="description" content={referenzen.meta_description}/>
                </Helmet>
                <Header className="reference" slogan={this.props.data.allStrapiReferenzenPages.edges[0].node.slogan}
                        blogphoto={{backgroundImage: `url(${this.props.data.allStrapiReferenzenPages.edges[0].node.photo.publicURL})`}} offersData={offersData}/>
                {referenzen && (
                    <main className="container reference pt">
                        <div className="row" id="content">
                            <div className="col-md-12 col-sm-12">
                                <h1>{referenzen.title}</h1>
                                <div className="info-card">
                                    <div className="subtitle">{referenzen.subtitle}</div>
                                    <div className="short-description">
                                        {referenzen.shortDescription}

                                        <div className="reference-img-video">
                                            <img
                                                alt={`${referenzen.title} -  Empfehlung`}
                                                src={referenzen.referenceImage.publicURL}
                                                className={`reference-img ${!referenzen.testimonial &&
                                                "no-testimonial"}`}
                                            />

                                            {(movie.length > 0) && (
                                                <div
                                                    className={`reference-videos ${this.state.slide ? "slide-in" : "slide-out"}`}>
                                                    <div
                                                        className="reference-video-icon"
                                                        onClick={() => this.toggle("slide")}>
                                                        <div className="reference-video-icon-picture"></div>
                                                    </div>
                                                    <div className="reference-video">
                                                        <div
                                                            className={`reference-video-list ${this.state.slide ? "slide-in-video" : "slide-out-video"}`}>
                                                            {movie.map(movies => {

                                                                return (
                                                                    <div className="reference-video-iframe">
                                                                        <iframe
                                                                            width="250" height="135"
                                                                            src={`https://www.youtube.com/embed/${movies.url}`}
                                                                            frameBorder="0"
                                                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                                            allowFullScreen
                                                                        ></iframe>
                                                                        <div
                                                                            className="reference-video-iframe-title">{movies.title}
                                                                        </div>
                                                                    </div>
                                                                )

                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>


                                    {referenzen.testimonial && (
                                        <div className="testimonial testimonial-big">
                                            <p>{referenzen.testimonial}</p>
                                            {referenzen.testimonialAuthor && (
                                                <strong>{referenzen.testimonialAuthor}</strong>
                                            )}
                                        </div>
                                    )}

                                    {referenzen.testimonial && (
                                        <div className="testimonial testimonial-small">
                                            <p>{referenzen.testimonial}</p>
                                            {referenzen.testimonialAuthor && (
                                                <strong>{referenzen.testimonialAuthor}</strong>
                                            )}
                                            {(movie.length > 0) && (
                                                <div className="reference-video-list-phone">
                                                    {movie.map(movies =>
                                                        <div className="reference-video-list-phone-iframe">

                                                            <iframe className="myiFrame" width="250" height="160"
                                                                    src={`https://www.youtube.com/embed/${movies.url}`}
                                                                    frameBorder="0"
                                                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                                    allowFullScreen
                                                            ></iframe>
                                                            <div className="reference-video-iframe-title">{movies.title}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}


                                    {referenzen.content && (
                                        <div id="content" className="reference-content">
                                            <ReactMarkdown children={referenzen.content}
                                                           rehypePlugins={[rehypeRaw]}/>

                                        </div>

                                    )}

                                    <div>
                                        {referenzen.technologies && (
                                            <h2 className={`open ${this.state.technologien ? 'h2-active' : null}`}
                                                onClick={() => this.toggle("technologien")}>Die
                                                Technologien</h2>
                                        )}
                                        {referenzen.technologies && technologien && (
                                            <div id="technologien" className="reference-text">
                                                <ReactMarkdown>{referenzen.technologiesContent}</ReactMarkdown>
                                                <ul className="technologies row">
                                                    {referenzen.technologies.map(tech => {
                                                        return (
                                                            <div>
                                                                {tech.icon && tech.icon.publicURL && (
                                                                    <li className="col-md-3 col-sm-3 col-xs-6">
                                                                        <a
                                                                            className="reference-technologien-link"
                                                                            href={tech.url}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            <img alt=""
                                                                                 src={tech.icon.publicURL}/>
                                                                            {tech.title}

                                                                        </a>
                                                                    </li>
                                                                )}
                                                                {!tech.icon && (
                                                                    <li className="col-md-3 col-sm-3 col-xs-6">
                                                                        <a
                                                                            href={tech.url}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            <img style={{opacity: 0}}
                                                                                 alt=''/>
                                                                            {tech.title}

                                                                        </a>
                                                                    </li>
                                                                )}
                                                            </div>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        )}
                                    </div>

                                    {(referenzen.teams.length > 0) && (
                                        <h2 className={`open ${this.state.author ? 'h2-active' : null}`}
                                            onClick={() => this.toggle("author")}>Die Verantwortlichen</h2>
                                    )}

                                    {(referenzen.teams.length > 0) && author && (
                                        <div className="references-author">
                                            <div className="flexcontainer">
                                                {referenzen.teams.map(team => {
                                                    return (
                                                        <div className="element nopadding">
                                                            <img src={team.portraitPhoto.publicURL}
                                                                 alt={`Foto ${team.name}`}/>
                                                            <div className="authorinfo">
                                                                <h3>{team.name}</h3>
                                                                <hr/>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div className="contact-button action-button-element"><a
                                                className="action-button" href="/kontakt"><h3
                                                className="action-button-text">Kostenloses Gespräch vereinbaren</h3></a>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </main>
                )}
            </Layout>
        )
    }
}

export const query = graphql`
  query ReferenzenDetails($id: String!) {
    strapiReferences(id: { eq: $id }) {
    content
    meta_title
    id
    companyImage {
      publicURL
    }
    referenceImage {
      publicURL
    }
    shortDescription
    subtitle
    filmes {
      title
      url
    }
    meta_description
    technologies {
      title
      icon {
        publicURL
      }
      url
    }
    testimonial
    testimonialAuthor
    title
    url
    movies
    technologiesContent
    teams {
      name
      linkedin
      email
      role
      portraitPhoto {
        publicURL
      }
    } 
  }
   allStrapiReferenzenPages {
    edges {
      node {
        photo {
          publicURL
        }
        slogan
      }
    }
  }
  allStrapiOfferPages {
        edges {
            node {
                title
                url
                order
            }
        }
    }
  }
`
